.add-reservation-host-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fafafa;
  min-height: calc(100vh - 64px);

  .add-reservation-host__join-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 21px 16px 32px 16px;

    .add-reservation-host__title-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .add-reservation-host__route {
        display: flex;
        gap: 8px;
        align-items: center;

        .add-reservation-host__route__arrow {
          filter: invert(94%) sepia(7%) saturate(226%) hue-rotate(179deg)
            brightness(92%) contrast(88%);
          width: 10px;
          height: 10px;
        }

        .add-reservation-host__route__first,
        .add-reservation-host__route__second {
          color: #475467;
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .add-reservation-host__title {
        font-family: "Montserrat";
        color: #151516;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
      }

      .add-reservation-host__subtitle {
        color: #4a4a57;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }

  .add-reservation-host-body__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;

    .add-reservation-host-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: var(--radius-xl, 12px);
      background: var(--Colors-Utility-colors-Secondary-background, #fff);
      padding: var(--spacing-xl, 16px) var(--spacing-xl, 16px)
        var(--spacing-3xl, 24px) var(--spacing-xl, 16px);
      width: 100%;
      padding: 21px 16px 32px 16px;
      max-width: 650px;

      .add-reservation-host-body__title-container {
        .add-reservation-host-body__title {
          font-family: "Montserrat";
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 0;
        }

        .add-reservation-host-host__subtitle {
          color: var(--Colors-Utility-colors-Tertiary-text, #6f7285);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .add-reservation-host-body__inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .add-reservation-host-body__input-container {
          display: flex;
          flex-direction: column;
          gap: 6px;

          span {
            color: var(--Colors-Utility-colors-Tertiary-text, #6f7285);
            font-family: "Montserrat";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          label {
            color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
            font-family: "Montserrat";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
          }

          .MuiOutlinedInput-notchedOutline {
            border: 0;
          }

          .add-reservation-host-body__input {
            border-radius: var(--radius-xl, 12px);
            width: 100%;
            border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
            background: var(--Colors-Utility-colors-Secondary-background, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            input {
              padding: 14px 10px;
              height: 75px;
            }
          }

          .dates {
            width: 100%;

            .labels-dates {
              color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
              font-family: "Montserrat";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
            }

            .un-desde,
            .un-hasta {
              margin-bottom: 16px;

              .datepicker {
                width: 50%;

                .MuiOutlinedInput-root {
                  height: 40px;
                  input {
                    border-radius: 8px 0px 0px 8px;
                    height: 40px;
                    text-align: center;
                    margin-bottom: 0px;
                    width: 100%;
                    border: 0px;
                    padding: 0;
                    text-align: center;
                    color: var(--Utility-Colors-Primary-Text);
                    border: 1px solid
                      var(--Colors-Custom-colors-divider-fg, #d1d1d1);
                    background: var(--Utility-Colors-Primary-Background);
                  }
                }
                .MuiOutlinedInput-notchedOutline {
                  border: 0px;
                }
              }
              .hour {
                width: 50%;
                border-radius: 0px 8px 8px 0px;
                border: 0px;
                height: 42px;
                color: var(--Utility-Colors-Primary-Text);
                background: var(--Utility-Colors-Primary-Background);
                text-align: center;
                border: 1px solid
                  var(--Colors-Custom-colors-divider-fg, #d1d1d1);

                fieldset {
                  border: 0px;
                }

                svg {
                  filter: var(--Custom-Colors-Svg);
                }
                .ant-picker-input {
                  input {
                    margin-bottom: 0;
                  }
                }
              }
            }

            label {
              color: var(--Utility-Colors-Primary-Text, #fff);
            }
          }

          .add-reservation-host-body__input-skeleton {
            width: 100%;
            height: 94px;
          }
        }
      }

      .add-reservation-host-footer {
        display: flex;
        margin-top: 40px;
        gap: 6px;
        flex-direction: column;

        .add-reservation-host-footer__cancel {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          background: var(--Colors-Utility-colors-Secondary-background, #fff);
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
        }

        .add-reservation-host-footer__add {
          background: var(--Colors-Brand-colors-Primary-color, #b72446);
          color: var(--Colors-Brand-colors-White-color-fix, #fff);
        }

        .add-reservation-host-footer__loading {
          background: var(--Colors-Custom-colors-Disabled-bg, #e9e9e9);
        }

        .add-reservation-host-footer__cancel,
        .add-reservation-host-footer__loading,
        .add-reservation-host-footer__add {
          width: 100%;
          height: 44px;
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          border-radius: var(--radius-xl, 12px);
          border: 1px solid var(--Colors-Custom-colors-divider-fg, #d1d1d1);
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .add-reservation-host-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 65px;

    .add-reservation-host__join-container {
      max-width: 1254px;
      width: 100%;
      margin: 0;
      padding: 32px 0 24px;

      .title-container {
        text-align: left;

        h1 {
          margin: 0;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 42px;
          line-height: 46px;
          color: #195154;
          padding: 44px 0 0 0;
        }
      }

      .btn-container {
        display: flex;
        align-items: flex-end;

        .btn-add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 30px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;

          p {
            margin: 0;
            padding: 0 6px 0 4px;
          }
        }
      }
    }

    .add-reservation-host-body__container {
      align-items: flex-start;
      max-width: 1254px;
      width: 100%;

      .add-reservation-host-body {
        .add-reservation-host-body__inputs {
          .add-reservation-host-body__input-container {
            .dates {
              display: flex;
              .un-hasta {
                .datepicker {
                  .MuiOutlinedInput-root {
                    input {
                      border-radius: 0px 0px 0px 0px;
                    }
                  }
                }
              }

              .un-hasta,
              .un-desde {
                width: 50%;

                .bootstrap-select {
                  width: 50% !important;
                }
              }

              .un-desde {
                .datepicker {
                  .MuiOutlinedInput-root {
                    input {
                      border-radius: 8px 0px 0px 8px;
                    }
                  }
                }
                .hour {
                  border-radius: 0px;
                }
              }
            }
          }
        }

        .add-reservation-host-footer {
          flex-direction: row;
          justify-content: flex-end;

          .add-reservation-host-footer__cancel,
          .add-reservation-host-footer__add,
          .add-reservation-host-footer__loading {
            width: 200px;
          }
        }
      }
    }
  }
}
